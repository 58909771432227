var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "rs-select",
        {
          staticClass: "select_width",
          staticStyle: { width: "300px", "margin-bottom": "10px" },
          attrs: { placeholder: "请选择" },
          on: { change: _vm.changeMallCode },
          model: {
            value: _vm.mallCode,
            callback: function ($$v) {
              _vm.mallCode = $$v
            },
            expression: "mallCode",
          },
        },
        _vm._l(_vm.mallList, function (item) {
          return _c("rs-option", {
            key: item.mallCode,
            attrs: { label: item.mallName, value: item.mallCode },
          })
        }),
        1
      ),
      _c("renderTable", {
        attrs: {
          list: _vm.tableData,
          stripe: false,
          columns: _vm.tableColumns,
        },
        on: { "row-dblclick": _vm.onRowClick },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }