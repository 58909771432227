<template>
    <div>
        <rs-select class="select_width" v-model="mallCode" @change="changeMallCode" placeholder="请选择" style="width:300px;margin-bottom:10px;">
            <rs-option
                    v-for="item in mallList"
                    :key="item.mallCode"
                    :label="item.mallName"
                    :value="item.mallCode">
            </rs-option>
        </rs-select>
        <renderTable :list="tableData" :stripe="false" :columns="tableColumns" @row-dblclick="onRowClick"></renderTable>
    </div>
</template>

<script>
    import Api from '@/api/reportServer'
    import Utils from '@/utils/utils'
    export default {
        name: "table.vue",
        data(){
            return{
                mallCode:'',
                mallList:[],
                tableColumns:[],
                tableData:[]
            }
        },
        methods:{
            getData(){
                Api.getReportData('1f1ea35da5564ba580cea77923cc2fb3',{}).then(res => {
                    res.data.data.metaData.columnNames.forEach((item,index) => {
                        this.tableColumns.push({
                            prop:index+'',
                            label: item
                        })
                    })
                    this.tableData = res.data.data.dataset
                })
            },
            async getMall(){
                let user = Utils.getSession("userInfo");
                let mall = await Api.getMall({'userId':user.userId})
                this.mallList = mall.data.data
                if(this.mallList.length > 0){
                    this.mallCode = this.mallList[0].mallCode
                    this.getData()
                }
            },
        },
        mounted(){
            this.getMall()
        }
    }
</script>

<style scoped>

</style>